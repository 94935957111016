module services {
    export module tariff {
        export class restrictionOptOutService implements interfaces.tariff.IRestrictionOptOutService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

          
            getList(): ng.resource.IResourceClass<interfaces.tariff.IRestrictionOptOut> {
                return this.$resource<interfaces.tariff.IRestrictionOptOut>(this.ENV.DSP_URL + "RestrictionOptOut/GetList", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            
            SaveRestrictionOptOuts(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RestrictionOptOut/SaveRestrictionOptOuts", {
                });
            }

        }
    }
    angular.module("app").service("restrictionOptOutService", services.tariff.restrictionOptOutService);
}